<template>
  <div class="date-picker" v-loading="dateLoading">
    <el-select
      :placeholder="
        fromDashboard && fieldPlaceholder ? fieldPlaceholder : 'Pick date range'
      "
      v-model="field.selected_date_picker"
      :size="'mini'"
      @change="setDatePickerDate"
      clearable
    >
      <el-option
        v-for="(el, index) in pickerOptions"
        :key="el.value + '_' + index"
        :value="el.value"
        :label="el.label"
        >{{ el.label }}
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";

export default {
  mixins: [CustomDashboardConfig],
  computed: {
    ...mapGetters("auth", ["getDefaultDateFormat"]),
  },
  props: {
    field: Object,
    fromDashboard: Boolean,
    fieldPlaceholder: String,
  },
  mounted() {
    // if (!this.field.selected_date_picker) {
    //   this.$set(this.field, "selected_date_picker", "");
    // }
  },
  data() {
    return {
      dateLoading: false,
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
      ],
    };
  },
  methods: {
    updateDateValue(picker) {
      console.log("picker", picker);
    },
    setDatePickerDate(value) {
      let data = this.getDatePickerHelper(value);
      if (this.fromDashboard) {
        this.$emit("dateFilterUpdated", {
          ...data,
          ...{
            entity_id: this.field.entity_id,
            operator: value,
            filter: this.field,
          },
        });
      } else {
        this.field.value = data.value;
        this.field.today = data.today;
      }
    },
    checkPicker(ev) {
      const format = "MM-DD-YYYY";
      let dt = moment(ev).format(format);
      let today = moment(new Date()).format(format);
      // let yesterday =
      // let weekAgo = moment
      if (dt == today) {
        this.field.selected_date_picker = "today";
      } else {
        this.field.selected_date_picker = "past";
      }
  
    },
  },
};
</script>
